


const PlayButton = (props) => {
    return <svg width="80" height="80" version="1.1" viewBox="0 0 21.167 21.167" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-43.768 -188.57)">
            <circle cx="54.351" cy="199.15" r="10.583" fill="#fff"/>
            <path d="m60.705 199.15-10.147 5.8582v-5.8582-5.8582l5.0734 2.9291z" fill={props?.color ?? "#0e2b63"}/>
        </g>
    </svg>
}

const VRButton = (props) => {
    return <svg width="80" height="80" version="1.1" viewBox="0 0 21.167 21.167" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-43.768,-188.57)">
            <circle cx="54.351" cy="199.15" r="10.583" fill="#fff"/>
            <path fill={props?.color ?? "#0e2b63"} d="m59.452 201.7c0 0.21537-0.11902 0.40242-0.30039 0.49877l-4.4775 2.5165c-0.09068 0.0679-0.20404 0.10197-0.32306 0.10197-0.11902 0-0.23238-0.0341-0.32306-0.10197l-4.4775-2.5165c-0.18137-0.0964-0.30039-0.2834-0.30039-0.49877v-5.101c0-0.21537 0.11902-0.40241 0.30039-0.49877l4.4775-2.5165c0.09068-0.0679 0.20404-0.10196 0.32306-0.10196 0.11902 0 0.23238 0.0341 0.32306 0.10196l4.4775 2.5165c0.18137 0.0964 0.30039 0.2834 0.30039 0.49877v5.101m-5.101-6.9997-3.378 1.8987 3.378 1.8987 3.378-1.8987-3.378-1.8987m-3.9674 6.6653 3.4007 1.9157v-3.8031l-3.4007-1.91v3.7974m7.9349 0v-3.7974l-3.4007 1.91v3.8031z" stroke-width=".56678"/>
        </g>
    </svg>
}

const ARButton = (props) => {
    return <svg width="80" height="80" version="1.1" viewBox="0 0 21.167 21.167" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-43.768,-188.57)">
            <circle cx="54.351" cy="199.15" r="10.583" fill="#fff"/>
            <path fill={props?.color ?? "#0e2b63"} d="m59.452 201.7c0 0.21537-0.11902 0.40242-0.30039 0.49877l-4.4775 2.5165c-0.09068 0.0679-0.20404 0.10197-0.32306 0.10197-0.11902 0-0.23238-0.0341-0.32306-0.10197l-4.4775-2.5165c-0.18137-0.0964-0.30039-0.2834-0.30039-0.49877v-5.101c0-0.21537 0.11902-0.40241 0.30039-0.49877l4.4775-2.5165c0.09068-0.0679 0.20404-0.10196 0.32306-0.10196 0.11902 0 0.23238 0.0341 0.32306 0.10196l4.4775 2.5165c0.18137 0.0964 0.30039 0.2834 0.30039 0.49877v5.101m-5.101-6.9997-3.378 1.8987 3.378 1.8987 3.378-1.8987-3.378-1.8987m-3.9674 6.6653 3.4007 1.9157v-3.8031l-3.4007-1.91v3.7974m7.9349 0v-3.7974l-3.4007 1.91v3.8031z" stroke-width=".56678"/>
        </g>
    </svg>
}

export {PlayButton, VRButton, ARButton};
