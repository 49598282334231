import nightEarth from "../assets/nightearthnasa.jpg";
import earth from "../assets/earthnasa.jpg";
import AFRAME from "aframe"
import "aframe-orbit-controls"
import "aframe-globe-component"
import "aframe-environment-component"

const getRandomGlobe = (nightChance) => {
    if(nightChance === undefined){
        nightChance = 1;
    }
    let night = Math.random() < nightChance;
    return night ? nightEarth : earth;
}

if(AFRAME.components['globe-changer'] === undefined){
    AFRAME.registerComponent('globe-changer', {
        init: function () {
            let element = this.el;
            element.setAttribute("globe", "globe-image-url: " + getRandomGlobe())
            this.lastUpdate = 0;
        },


        /*tick: function (){
            if(this.lastUpdate++ < 100) {
                return;
            }
            this.el.setAttribute("globe", "globe-image-url: " + getRandomGlobe())
            this.lastUpdate = 0;
        }*/
    });
}

if(AFRAME.components['artist-image'] === undefined){
    AFRAME.registerComponent('artist-image', {
        init: function () {


            this.el.addEventListener('click', (event) => {
                const evt = new CustomEvent('artist-clicked', { bubbles: true, detail: {artist: this.data.artistId} });

                event.target.dispatchEvent(evt)
            });

        },

        schema: {
            artistId: {type: 'string', default: 5}
        }
    });
}
