import {Component} from "react";

function getPath(dict, path, index=0){
    if(index === path.length || dict === undefined) {
        return dict;
    }
    return getPath(dict?.[path[index]], path, index + 1)
}


class ArtInfoTable extends Component{
    // we need order


    render(){
        let rows = []
        for (let field of this.props.schema) {
            let path = field.source.split(".")
            let data = getPath(this.props, path)
            if (field.clickable) {
                // eslint-disable-next-line
                data = <a href="javascript:void(0)" onClick={() => {this.props.handleClick?.(field.source)}}>{data}</a>
            }
            if (data !== undefined) {
                rows.push(
                    <tr key={field.source}>
                        <th>{field.displayName}</th>
                        <td>{data}</td>
                    </tr>
                )
            }
        }

        return <div className="artInfo">
            <table>
                <tbody>{rows}</tbody>
            </table>
        </div>
    }
}

export default ArtInfoTable;