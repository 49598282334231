import {Component} from "react";
import "./image.css"
import {RingLoader} from "react-spinners";


class Image extends Component {

    render = () => {
        return <div className="ImageContainer">

            <div className="ImageBackground" style={{backgroundImage: "url('" + this.props.asset.src + "')"}}/>
            <ImageWithLoader key={this.props.asset.src} src={this.props.asset.src} alt={this.props.asset.title} />
            {this.props.asset.title && <div className="ImageTitle">{this.props.asset.title}</div>}
        </div>



    }
}

class ImageWithLoader extends Component {

    constructor(props) {
        super(props);
        //alert("loading")
        this.state = {
            loaded: false
        }
    }

    handleImageLoad = () => {
        this.setState({
            loaded: true
        })
        //const width = this.divRef.clientWidth;
        //alert(width)
    }

    componentDidMount = () => {
    }

    render() {
        return <>
            <div className="Loader" ><RingLoader color="white"/></div>
            <img ref={divRef => this.divRef = divRef} onLoad={this.handleImageLoad} className={this.state.loaded? "ImageLoaded" : "ImageLoading"} src={this.props.src} alt={this.props.title} />
        </>
    }
}

export default Image;