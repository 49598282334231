import "./image.css";
import React, {Component} from "react";

const Poster = (props) => {
    return <div className="Poster" onClick={props.onClick}>
        <div className="ImageBackground" style={{backgroundImage: "url('" + props.image + "')"}} />
        {props.children}
    </div>


};

class ClosingPoster extends Component{
    constructor(props) {
        super(props);
        this.state = {
            posterClosed: false
        }
    }

    handleClick = () => {
        this.setState({
            posterClosed: true
        })
    };

    render() {
        if(!this.state.posterClosed){
            return <Poster image={this.props.poster} onClick={this.handleClick}>
                {this.props.button ?? null}
            </Poster>
        }

        return this.props.children
    }
}

export default Poster
export {ClosingPoster}
