import React, {Component} from 'react';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';
import "./VideoPlayer.css"
import {ClosingPoster} from "./Poster";
import {PlayButton} from "./Buttons";


class Video extends Component {

    render(){
        let posterEnabled = this.props.poster !== undefined;
        let autoplay = this.props.autoplay === true;



        let content = <ShakaPlayer
            src={this.props.src}
            chromeless={autoplay}
            autoPlay={autoplay || posterEnabled}
            loop={autoplay}
            muted={autoplay}
            ref={this.player}
        />;

        if(posterEnabled){
            content = <ClosingPoster button={PlayButton()} poster={this.props.poster}>
                {content}
            </ClosingPoster>
        }


        return <div className="videoPlayer">
            {content}
        </div>


    }
}

export default Video
