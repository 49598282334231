import {Component} from "react"
import {ArtistMiniature} from "./content/BasicContent";
import {getArtist} from "../utils/artStructureTools";
import FormattedText from "./content/FormattedText";
import history from "../history";


class ArtList extends Component{
    render = () => {

        let arts = []

        for(let art of this.props.arts ?? []){
            arts.push(<SingleArt art={art} key={art.uuid} artist={getArtist(art, this.props.artists)}/>)
        }

        return <div className="ArtList">
            <h1>ALL ARTS</h1>
            {arts}
        </div>;
    }
}

class SingleArt extends Component {
    render = () => {
        return <div className="SingleArt" onClick={() => {history.push("/art/" + this.props.art.uuid)}}>
            <ArtistMiniature image={this.props.art.cover ?? this.props.artist?.avatar}/>

            <div className="ArtDescription" onClick={() => {history.push("/art/" + this.props.art.uuid)}}>
                <h2>{this.props.art.name}</h2>
                <h3>{this.props.artist?.name}</h3>
                <FormattedText text={this.props.art?.description} />
            </div>
            <div className="buttons">
                {this.props.art.uuid && <button onClick={() => {history.push("/art/" + this.props.art.uuid)}}>See detail</button>}
                {this.props.artist?.uuid && <button onClick={() => {history.push("/artist/" + this.props.artist.uuid)}}>About artist</button>}
            </div>

        </div>
    }
}

export default ArtList;