import React from "react";

class ControlsOverlay extends React.Component {
    render() {
        return <div className="globeControl" >

            {this.props.children}
            <div className={["social", this.props.hideSocial ? "hidden" : "shown"].join(" ")}>
                <button className="socialLink facebook" onClick={() => {window.open("//www.facebook.com/Industrabrno/",'_blank');}}/>
                <button className="socialLink twitter" onClick={() => {window.open("//twitter.com/industrabrno?lang=cs",'_blank');}}/>
                <button className="socialLink youtube" onClick={() => {window.open("//www.youtube.com/channel/UCK87h0hVnDRc7ijIhrIcnDg",'_blank');}}/>
                <button className="socialLink about" onClick={this.props.infoClicked}/>
            </div>
            <div className="siteName">
                PLAIN FUTURE
            </div>
            <div className="menu" onClick={this.props.menuClicked}>
                MENU
            </div>
            <div className="artistLink">
                10 artists
            </div>
        </div>
    }
}

export default ControlsOverlay
