import {Component} from "react"
import FormattedText from "./content/FormattedText";
import history from "../history";


class ArtistList extends Component{
    render = () => {

        let artists = [];

        for(let artist of this.props.artists ?? []){
            artists.push(<SingleArtist artist={artist}/>)
        }

        return <div className="ArtList">
            <h1>List of artists</h1>
            {artists}
        </div>;
    }
}

class SingleArtist extends Component {
    render = () => {
        return <div className="SingleArt" onClick={() => {history.push("/artist/" + this.props.artist.uuid)}}>

            <div className="ArtDescription" onClick={() => {history.push("/artist/" + this.props.artist.uuid)}}>
                <h2>{this.props.artist.name}</h2>
                <FormattedText text={this.props.artist.bio} />
            </div>
            <div className="buttons">
                {this.props.artist.uuid && <button onClick={() => {history.push("/artist/" + this.props.artist.uuid)}}>Details</button>}
            </div>

        </div>
    }
}

export default ArtistList;
