function InfoCard(){
    return <div>
        <h1>Plain Future</h1>
        Hello, this is Plain future, a sample React app based on New Future, but without New Future branding, proprietary fonts and copyrighted artistic works.

        <p>All works here are under some form of CC licence or public domain</p>

        <p>Lorem ipsum and so on.</p>

        <p>Stop waiting for your Plain Future. <b>Create it.</b></p>
    </div>
}

export default InfoCard
