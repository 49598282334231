import ContentChanger from "./ContentChanger";
import {Iframe} from "./BasicContent";
import Video from "./Video";
import Image from "./Image";
import Link from "./Link";
import OutOfOrder from "./OutOfOrder";
import GLTFObject from "./GLTFObject";

function DisplayAssets(props) {
    if(props?.assets === undefined){
        return <></>;
    }

    let allAssets = [];

    for(let asset of props.assets){
        if(asset.type === "iframe"){
            allAssets.push(<Iframe key={asset.src} src={asset.src}/>);
        } else if(asset.type === "image"){
            allAssets.push(<Image asset={asset}/>);
        } else if(asset.type === "video"){
            allAssets.push(<Video key={asset.src} poster={asset.poster} src={asset.src} autoplay={asset.autoplay} />);
        } else if(asset.type === "link"){
            allAssets.push(<Link poster={asset.poster} href={asset.href} text={asset.text}/>);
        } else if(asset.type === "gltf"){
            allAssets.push(<GLTFObject object={asset.object} poster={asset.poster}/>);
        } else if(asset.type === "tempDisabled"){
            allAssets.push(<OutOfOrder/>);
        }
    }

    return <ContentChanger>

        {allAssets}




    </ContentChanger>
}

export default DisplayAssets;
