/*
    Util to transform array JSON objects containing property 'uuid'
    to JS dictionary where uuid is a key.
 */

const tranformToUUIDDictionary = (array) => {
    let result = {}
    for (let object of array){
        if(object.uuid !== undefined) {
            result[object.uuid] = object
        }
    }
    return result;
}

const getArtist = (art, allArtists) => {
    if(art?.author === undefined || !allArtists){
        return null;
    }
    let result = allArtists[art.author]
    return result !== undefined ? result : null;
}

const getAttribute = (art, artist, attribute) => {
    let result = art[attribute]
    if(result === undefined) {
        result = artist !== null ? artist[attribute] : undefined
    }
    return result !== undefined ? result : null;
}

const getArtLocation = (art, artist) => {
    return getAttribute(art, artist, 'location')
}

const getArtImage = (art, artist) => {
    return getAttribute(art, artist, 'avatar')
}


export {tranformToUUIDDictionary, getArtImage, getArtist, getArtLocation, getAttribute}
