import {Component} from "react"

class ContentChanger extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayed: 0
        }
    }

    handleStateChange = (change) => {
        if(change === undefined){
            change = 1;
        }
        this.setState({
            displayed: (this.state.displayed + change + this.props.children.length) % this.props.children.length
        })
    };

    render() {
        if(this.props.children.length <= 0){
            return null;
        }
        let displayArrows = this.props.children.length > 1;
        return <div className="artContent">
            {displayArrows && <div onClick={() => {this.handleStateChange(-1)}} className="arrowContainer left"/>}
            {displayArrows && <div onClick={() => {this.handleStateChange(+1)}} className="arrowContainer right"/>}
            <div className={"replaceableContent" + (displayArrows ? "" : " noarrows")}>
                {this.props.children[this.state.displayed]}
            </div>

        </div>
    }


}

export default ContentChanger
