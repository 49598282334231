function Iframe(props){
    return <iframe src={props.src} frameBorder="0"
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                   allowFullScreen title="Video on external server"
    />
}

function ArtistMiniature(props){
    return <img className="artistMiniature" src={props.image} alt="Artist" />
}

export {ArtistMiniature, Iframe}