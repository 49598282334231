
// Todo: Markdown support

const FormattedText = (props) => {
    if(!props?.text){
        console.log(props)
        return null;
    }
    let paragraphs = props?.text.split("\n");
    let i = 0;
    console.log(paragraphs.map((text) => (<p key={i++}>{text}</p>)))
    return paragraphs.map((text) => (<p key={i++}>{text}</p>));

}

export default FormattedText;