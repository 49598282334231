import {ClosingPoster} from "./Poster";
import {VRButton} from "./Buttons";
import React from "react";


const ObjectViewer = (props) => {

    let renderedContent = <div className="artContent vrMode"><div className="vrContent">
        <a-scene embedded  vr-mode-ui="enabled: true" loading-screen="dotsColor: white; backgroundColor: #ffd083" >

            <a-assets>
                <a-asset-item id="object" src={props.object} timeout="10000"/>
            </a-assets>
            <a-sky color="#ffebff"/>
            <a-entity camera="fov: 45" orbit-controls="target: 0 0 0; maxPolarAngle: 180; initialPosition: 2.7 3.3 -4.05;" />
            <a-entity gltf-model={props.object}/>

        </a-scene>
    </div></div>;


    if(props.poster) {
        renderedContent = <ClosingPoster button={VRButton()} poster={props.poster}>
            {renderedContent}
        </ClosingPoster>

    }


    return renderedContent
};


export default ObjectViewer;
