import React, {Component} from "react";
import "./aframe-components/globe-changer.js"
import "aframe-event-set-component"
import {polar2Cartesian} from "./utils/coordsTranslate";
import nightEarth from "./assets/nightearthnasa.jpg";

/**
 * This code does NOT follow React best practices on purpose.
 * Used mostly as a wrapper around A-Frame code, because using pure React for VR is generally discouraged.
 * See initial note in aframe-react package[1].
 *
 * [1]: https://github.com/supermedium/aframe-react/blob/master/README.md
 */

class Globe extends Component{
    constructor(props) {
        super(props);
        this.randomPlanes = []
        if(props.dataPoints !== undefined){
            for(let point of props.dataPoints){
                let lat = point.location[0]
                let lon = point.location[1]
                let minAltitude = 0.05 * Math.random();
                let maxAltitude = minAltitude + 0.02

                let maxPosition = this.getPosition(lat, lon, maxAltitude);
                let minPosition = this.getPosition(lat, lon, minAltitude);

                let radius = Math.random() * 2 + 2;

                let rotationString = [360-lat, lon, 0].join(" ");
                this.randomPlanes.push(
                    (<a-entity position={minPosition}
                               key={point.uuid}
                               scale="0 0 0"
                               artist-image={"artistId: " + point.id + ";"}
                               material="opacity: 0.5; transparent: true;"
                               rotation={rotationString}
                               animation__appear={"property: scale; from: 0 0 0; to: 1 1 1; dur: 400; easing: easeInOutQuad; delay: " + (2000 + Math.random() * 2000) + ";"}
                               animation__mouseenter={"property: position; to: " + maxPosition + "; easing: easeInOutQuad; startEvents: mouseenter; dur: 100"}
                               animation__mouseleave={"property: position; to: " + minPosition + "; easing: easeInOutQuad; startEvents: mouseleave; dur: 100"}>
                        <a-circle radius={radius} position="0 0 0.1" src={point.image}/>

                        <a-circle radius={radius + 1} color="#ffffff" side="double" class="raycastable"/>
                    </a-entity>)
                )
                /*
                event-set__enter="_event: mouseenter; color: #026fc9"
                 event-set__leave="_event: mouseleave; color: #4CC3D9"
                 */
            }
        }

    }

    componentDidMount() {
        this.scene.addEventListener("artist-clicked", this.handleEvent)

    }

    componentWillUnmount() {
        this.scene.removeEventListener("artist-clicked", this.handleEvent)
    }

    handleEvent = (event) => {
        if(this.props.artistClicked !== undefined){
            this.props.artistClicked(event.detail.artist)
        }
    }

    render(){
        let position = this.props.position
        let classes = ["aframe-shit"]
        if(position !== undefined){
            classes.push(position)
        }

        /*return <div className={classes.join(" ")} ref={elem => this.scene = elem} onClick={this.props.onClick}>
            <GLTFObject object="/motion.glb" />
        </div>*/

        return (
            <div className={classes.join(" ")} onClick={this.props.onClick}>
                <a-scene ref={elem => this.scene = elem} embedded vr-mode-ui="enabled: false" loading-screen="dotsColor: white; backgroundColor: #ff84ff">
                    <a-entity position="0 0 0" scale="0.015 0.015 0.015" globe={"globe-image-url: " + nightEarth}>
                        {this.randomPlanes}
                    </a-entity>
                    <a-sky color="#0a3d62"></a-sky>
                    <a-entity orbit-controls="enableZoom: false; target: 0 0 0; maxPolarAngle: 180; initialPosition: 0 2 2; autoRotate:true; autoRotateSpeed: -0.02" raycaster="objects: .raycastable" cursor="rayOrigin: mouse" ></a-entity>

                </a-scene>
            </div>
        )
    }

    getPosition(lat, lon, radius){
        let position = polar2Cartesian(lat, lon, radius);
        let positionString = [position.x, position.y, position.z].join(" ");
        return positionString
    }
}

export default Globe;
