import {Component} from "react";
import history from "./history";
import "./Menu.css"

const Menu = (props) => (
    <div onClick={props.onClose} className="Menu">
        <nav>
            <ol>
                <li><a href={history.createHref({ pathname: "/info"})}>O projektu</a></li>
                <li><a href={history.createHref({ pathname: "/art-list"})}>Seznam děl</a></li>
                <li><a href={history.createHref({ pathname: "/artist-list"})}>Seznam autorů</a></li>
            </ol>

            <button className="closeButton"/>

        </nav>

    </div>
)

class AnimatedMenu extends Component {

    render = () => {
        return this.props.open ? <Menu onClose={this.props.onClose}/> : null
    }
}

export default AnimatedMenu;