import {Component} from "react";
//import {ArtistMiniature} from "./content/BasicContent";
import {Redirect} from "react-router-dom";
import ArtInfoTable from "./content/ArtInfoTable";
import DisplayAssets from "./content/DisplayAssets";
import FormattedText from "./content/FormattedText";

const infoTableSchema = [
    {
        source: "artist.name",
        displayName: "Name"
    },
    {
        source: "artist.locationName",
        displayName: "Location"
    }
]

class ArtistCard extends Component {
    openInNewWindow = web => {
        window.open(web,'_blank');
    }

    render() {
        if(this.props.artist === undefined){
            // TODO: Change me to proper 404.
            return <Redirect to="/404"/>
        }



        return (
            <>
                <div className="infoCard">
                    {/*<ArtistMiniature image={this.props.artist.avatar}/>*/}
                    <ArtInfoTable artist={this.props.artist} schema={infoTableSchema}/>
                    <DisplayAssets assets={this.props.artist.assets}/>
                    <div className="artDescription">
                        <FormattedText text={this.props.artist.bio} />
                    </div>
                    <div className="buttons" >
                        {this.props.artist.web && <button onClick={() => {this.openInNewWindow(this.props.artist.web)}}>Portfolio autora</button>}
                    </div>
                </div>
            </>
        )
    }
}

export default ArtistCard;
