import {Component} from "react"
import history from "../history";
import {withRouter} from "react-router-dom";
import ArtInfoTable from "./content/ArtInfoTable";
import DisplayAssets from "./content/DisplayAssets";
import FormattedText from "./content/FormattedText";
import DefaultVRScene from "./content/DefaultVRScene";

const tableSchema = [
    {
        source: "artist.name",
        displayName: "Artist",
        clickable: true
    },
    {
        source: "art.name",
        displayName: "Artwork"
    },
    {
        source: "art.published",
        displayName: "Year of origin"
    },
    {
        source: "artist.locationName",
        displayName: "Country"
    }
]

class ArtCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vrShown: false
        }
    }

    handleArtistNameClick = () => {
        if(this.props.artist !== undefined) {
            history.push("/artist/" + this.props.artist?.uuid)
        }
    }

    handleVrButtonClick = () => {
        this.setState({
            vrShown: !this.state.vrShown
        })
    }

    render() {
        if(this.props.art === undefined){
            // TODO: Change me to proper 404.
            return "404"
        }

        return (
            <>
                <div className="infoCard">
                    <ArtInfoTable art={this.props.art} artist={this.props.artist}
                                  handleClick={this.handleArtistNameClick} schema={tableSchema}/>
                    {
                        this.state.vrShown ?
                            <DefaultVRScene assets={this.props.art?.assets} /> :
                            <DisplayAssets assets={this.props.art?.assets}/>
                    }
                    <div className="artDescription">
                        <FormattedText text={this.props.art.description} />
                    </div>
                    <div className="buttons">
                        {<button onClick={this.handleVrButtonClick}>{this.state.vrShown ? "O díle" : "Zobrazit ve VR"}</button>}
                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(ArtCard)