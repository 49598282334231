import React from "react";
import ArtCard from "./content-cards/ArtCard"
import ControlsOverlay from "./ControlsOverlay";
import Globe from "./Globe";
import artists from "./people.json"
import arts from "./arts.json"
import {getArtImage, getArtist, getArtLocation, tranformToUUIDDictionary} from "./utils/artStructureTools";
import {Router, Route, Switch} from "react-router-dom";
import history from "./history";
import ArtistCard from "./content-cards/ArtistCard";
import InfoCard from "./content-cards/InfoCard";
import Menu from "./Menu";
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";
import NotFoundCard from "./content-cards/NotFoundCard";
import ArtList from "./content-cards/ArtList";
import ArtistList from "./content-cards/ArtistList";

let artsDict = tranformToUUIDDictionary(arts);
let artistsDict = tranformToUUIDDictionary(artists);

class NewFutureWeb extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: history.location.pathname !== "/"
        }
    }

    interval = null;

    isCardOpen = () => {
        return history.location.pathname !== "/";
    }

    handleMenuOpen = () => {
        this.setState({menuOpen: true})
    }

    handleMenuClose = () => {
        this.setState({menuOpen: false})
    }

    closeCard = () => {
        history.push("/")
        if(!this.state.open){
            return;
        }
        this.setState({
            open: false,
            menuOpen: false
        })
    }

    artLocations = null;

    getArtLocations = () => {
        if(this.artLocations != null){
            return this.artLocations;
        }

        this.artLocations = []
        for(let art of Object.values(artsDict)){
            let artist = getArtist(art, artistsDict)
            let location = getArtLocation(art, artist);
            let artImage = getArtImage(art, artist)
            if(location === null || artImage === null){
                console.error("Can't show art with uuid " + art.uuid + " on the map because it has no known location or artImage")
                continue;
            }

            if(art.assets === undefined){
                console.error("Can't show art with uuid " + art.uuid + " on because there is no asset.")
                continue;
            }

            this.artLocations.push({
                id: art.uuid,
                location: location,
                image: artImage
            })
        }
        return this.artLocations
    }

    /*
    {location: location, image: image, id: uuid}
     */

    displayInfo() {
        history.push("/info")
        this.setState({
            open: true
        })
    }

    getArtistByArtUuid(artUuid){
        return getArtist(this.getArtByUuid(artUuid), artistsDict)
    }

    getArtistByUuid(artistUuid) {
        return artistsDict[artistUuid];
    }

    getArtByUuid(artUuid) {
        return artsDict[artUuid];
    }

    handleArtistClicked = (artId) => {
        history.push("/art/" + artId)
        this.setState({
            open: true
        })
    }

    render() {
        let cardOpen = this.props.location.pathname !== "/";

        return (
           <>
                <Menu open={this.state.menuOpen} onClose={this.handleMenuClose}/>
                <ControlsOverlay
                    hideSocial={cardOpen}
                    infoClicked={() => {this.displayInfo()}}
                    menuClicked={this.handleMenuOpen}
                >
                    <Globe
                        position={cardOpen ? "top" : "center"}
                        dataPoints={this.getArtLocations()}
                        artistClicked={this.handleArtistClicked}

                    />
                    <ClickInterceptor active={cardOpen} onClick={this.closeCard}/>
                </ControlsOverlay>
                <div id="contentContainer" className={cardOpen ? "open" : "closed"}>
                    <div id="contentCard">
                        <TransitionGroup>
                            <CSSTransition
                                key={cardOpen}
                                classNames="cardFade"
                                timeout={{exit: 300}}>
                                <div>
                                    <Switch location={this.props.location}>
                                        <Route exact={true} path="/"/>
                                        <Route exact={true} path="/info" component={InfoCard}/>
                                        <Route path="/art/:uuid" render={({match}) => (
                                            <ArtCard art={this.getArtByUuid(match.params.uuid)} artist={this.getArtistByArtUuid(match.params.uuid)}/>
                                        )}/>
                                        <Route path="/artist/:uuid" render={({match}) => (
                                            <ArtistCard artist={this.getArtistByUuid(match.params.uuid)}/>
                                        )}/>
                                        <Route path="/art-list" render={({match}) => (
                                            <ArtList arts={Object.values(artsDict)} artists={artistsDict}/>
                                        )}/>
                                        <Route path="/artist-list" render={({match}) => (
                                            <ArtistList artists={Object.values(artistsDict)}/>
                                        )}/>
                                        <Route component={NotFoundCard}/>
                                    </Switch>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>

            </>
        );
    }
}

const App = () => {
    return <Router history={history}>
        <Route component={NewFutureWeb}/>
    </Router>
}

class ClickInterceptor extends React.Component {
    render() {
        if(!this.props.active){
            return null;
        }
        return <div className="clickInterceptor" onClick={this.props.onClick}/>;
    }
}

export default App;
